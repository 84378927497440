import _ from 'lodash'
import { parse, parseISO, fromUnixTime, format, formatISO, getUnixTime } from 'date-fns'
import { DateTimeType, dateTimeTypesToValueFormats } from '../resources/dateTimeSettings'
import Portal from '../portal'

/**
 * @typedef {'timestamp'|'unixtime'|'isoString'} DateTimeSpecialFormat
 */

/** @type {Object.<DateTimeSpecialFormat, DateTimeSpecialFormat>} */
export const DateTimeSpecialFormat = Object.freeze({
  TIMESTAMP: 'timestamp', // milliseconds
  UNIXTIME: 'unixtime', // seconds
  ISO_STRING: 'isoString'
})

// TODO: pass locale to date-fns
/**
 * Wrapper for Moment API
 * Handles additional date formats
 * @param {string|number} dateTime
 * @param {string|DateTimeSpecialFormat} inputFormat
 * @param {string|DateTimeSpecialFormat} outputFormat
 * @returns {number|string}
 */
export function formatDateTime(dateTime, inputFormat, outputFormat) {
  let parsedDateTime

  switch (inputFormat) {
    case DateTimeSpecialFormat.ISO_STRING:
      parsedDateTime = parseISO(dateTime)
      break
    case DateTimeSpecialFormat.TIMESTAMP:
      parsedDateTime = new Date(dateTime)
      break
    case DateTimeSpecialFormat.UNIXTIME:
      parsedDateTime = fromUnixTime(dateTime)
      break
    default:
      parsedDateTime = parse(dateTime, inputFormat, new Date())
  }

  switch (outputFormat) {
    case DateTimeSpecialFormat.ISO_STRING:
      return formatISO(parsedDateTime)
    case DateTimeSpecialFormat.TIMESTAMP:
      return parsedDateTime.getTime()
    case DateTimeSpecialFormat.UNIXTIME:
      return getUnixTime(parsedDateTime)
    default:
      return format(parsedDateTime, outputFormat)
  }
}

/**
 * @param {string} dateTimeType
 * @returns {number|string}
 */
export function getDateTimeTypeDisplayFormat(dateTimeType) {
  return Portal.settings.dateTimeTypesToDisplayFormats[dateTimeType]
}

/**
 * @param {string|number} dateTime
 * @param {string|DateTimeSpecialFormat} inputFormat
 * @param {string} outputDateTimeType
 * @returns {number|string}
 */
export function dateTimeToDisplayFormat(dateTime, inputFormat, outputDateTimeType) {
  const outputFormat = getDateTimeTypeDisplayFormat(outputDateTimeType)
  return formatDateTime(dateTime, inputFormat, outputFormat)
}

// shorthand functions

/**
 * @param {string} dateTime
 * @returns {string}
 */
export const isoStringToDatetime = _.partial(
  formatDateTime,
  _,
  DateTimeSpecialFormat.ISO_STRING,
  dateTimeTypesToValueFormats[DateTimeType.DATETIME]
)

/**
 * @param {string} dateTime
 * @returns {string}
 */
export const datetimeToIsoString = _.partial(
  formatDateTime,
  _,
  dateTimeTypesToValueFormats[DateTimeType.DATETIME],
  DateTimeSpecialFormat.ISO_STRING
)

/**
 * @param {string} dateTime
 * @returns {number}
 */
export const isoStringToTimestamp = _.partial(
  formatDateTime,
  _,
  DateTimeSpecialFormat.ISO_STRING,
  DateTimeSpecialFormat.TIMESTAMP
)

/**
 * @param {number} dateTime
 * @returns {string}
 */
export const timestampToIsoString = _.partial(
  formatDateTime,
  _,
  DateTimeSpecialFormat.TIMESTAMP,
  DateTimeSpecialFormat.ISO_STRING
)

/**
 * @param {string} dateTime
 * @returns {number}
 */
export const dateToTimestamp = _.partial(
  formatDateTime,
  _,
  dateTimeTypesToValueFormats[DateTimeType.DATE],
  DateTimeSpecialFormat.TIMESTAMP
)

/**
 * @param {number} dateTime
 * @returns {string}
 */
export const timestampToDate = _.partial(
  formatDateTime,
  _,
  DateTimeSpecialFormat.TIMESTAMP,
  dateTimeTypesToValueFormats[DateTimeType.DATE]
)

/**
 * @param {string} dateTime
 * @returns {string}
 */
export const isoStringToDisplayDateTime = _.partial(
  dateTimeToDisplayFormat,
  _,
  DateTimeSpecialFormat.ISO_STRING,
  DateTimeType.DATETIME
)

/**
 * @param {string} dateTime
 * @returns {string}
 */
export const isoStringToDisplayDate = _.partial(
  dateTimeToDisplayFormat,
  _,
  DateTimeSpecialFormat.ISO_STRING,
  DateTimeType.DATE
)

/**
 * @param {string} dateTime
 * @returns {string}
 */
export const dateToDisplayDate = _.partial(
  dateTimeToDisplayFormat,
  _,
  dateTimeTypesToValueFormats[DateTimeType.DATE],
  DateTimeType.DATE
)
